<template>
  <div ref="ctxRef" class="fill-box absolute -left-4 top-2 hidden -translate-x-full lg:block">
    <svg width="108" height="102" viewBox="0 0 108 102" fill="none">
      <path
        class="fx-line"
        d="M0.867188 100.05H34.9672L102.067 5.44995"
        stroke="#12E2FF"
        stroke-width="2.2"
      />
    </svg>
    <span class="fx-circle absolute right-0 top-0 block h-2.5 w-2.5 rounded-full bg-cyan"></span>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'

const ctxRef = ref<HTMLElement | null>(null)
let ctx: any = null
let tl: GSAPTimeline | null = null

onMounted(() => {
  gsap.registerPlugin(DrawSVGPlugin)

  if (ctxRef.value) {
    const mm = gsap.matchMedia()
    mm.add('(min-width: 1024px)', () => {
      ctx = gsap.context((self: any) => {
        gsap.set(self.selector('.fx-line'), { drawSVG: '100% 100%' })
        gsap.set(self.selector('.fx-circle'), { scale: 0 })

        tl = gsap.timeline({
          delay: 0.2
        })
        tl.to(self.selector('.fx-circle'), {
          duration: 0.4,
          scale: 1,
          ease: 'back.out'
        })
        tl.to(
          self.selector('.fx-line'),
          {
            duration: 0.6,
            drawSVG: '0 100%',
            ease: 'power1.out'
          },
          '-=0.2'
        )
      }, ctxRef.value as HTMLElement)
    })
  }
})

onUnmounted(() => {
  if (ctx) {
    ctx.revert()
  }
})
</script>
