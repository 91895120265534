<template>
  <div ref="targetRef" class="grid-default">
    <div class="col-span-full lg:col-span-6 xl:col-start-2">
      <ClientOnly v-if="spline && !isMobile" fallback-tag="div" fallback="Loading...">
        <SplineRuntime
          :url="spline"
          :width="660"
          :height="880"
          class="hidden aspect-3/4 max-w-full lg:block"
          :activeIdx="activeIdx"
          responsive
        />
        <!-- <CanvasStageSwitcher :w="660" :h="880" />-->
      </ClientOnly>
    </div>
    <div class="col-span-full lg:col-span-6 lg:h-full xl:col-span-4">
      <div
        role="presentation"
        class="mx-auto flex h-full max-w-[420px] flex-col justify-center gap-3 lg:gap-8 lg:pl-20 xl:pl-14"
      >
        <AccordionFeaturesPanel
          v-for="(item, idx) in data"
          :key="idx"
          :idx="Number(idx)"
          :active="activeIdx === Number(idx)"
          class="!border-none"
          stateful
          @expand="expandAccordion(Number(idx))"
        >
          <template #title>
            <span
              class="relative flex items-center gap-2 transition-colors duration-300 ease-in-out hover:text-white"
            >
              <AccordionFeaturesHandle v-if="activeIdx === Number(idx)" />
              <span class="lg:hidden">
                <svg
                  class="relative -top-0.5 h-auto w-6"
                  width="33"
                  height="33"
                  viewBox="0 0 33 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    v-show="activeIdx !== Number(idx)"
                    d="M16.1445 7.45044V25.4504"
                    stroke="#12E2FF"
                    stroke-width="4"
                    stroke-miterlimit="10"
                    stroke-linecap="square"
                  />
                  <path
                    d="M7.14453 16.4504H25.1445"
                    stroke="#12E2FF"
                    stroke-width="4"
                    stroke-miterlimit="10"
                    stroke-linecap="square"
                  />
                </svg>
              </span>
              <span class="self-end drop-shadow-[0px_0px_64px_#12E2FF]">{{ item.title }}</span>
            </span></template
          >
          <template #content>
            <div>
              <div v-if="isMobile" class="aspect-[4/3] overflow-hidden px-4">
                <Image
                  class="object-cover"
                  :src="
                    item.media.responsiveImage ? item.media.responsiveImage?.src : item.media.url
                  "
                  :srcset="
                    item.media.responsiveImage ? item.media.responsiveImage?.webpSrcSet : undefined
                  "
                  :width="
                    item.media.responsiveImage
                      ? item.media.responsiveImage?.width
                      : item.media.width
                  "
                  :height="
                    item.media.responsiveImage
                      ? item.media.responsiveImage?.height
                      : item.media.height
                  "
                  :alt="item.media.alt!"
                />
              </div>
              <div class="pb-2 text-sm lg:text-body" v-html="item.body"></div>
            </div>
          </template>
        </AccordionFeaturesPanel>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { FileField } from '~/types/datocms'

interface AllInOneFeaturesProps {
  data: {
    title: string
    body: string
    media: FileField
  }
  spline: any
}

defineProps<AllInOneFeaturesProps>()

const activeIdx = ref<number>(0)
const { isMobile } = useDevice()

function expandAccordion(idx: number) {
  activeIdx.value = idx
}

const targetRef = ref(null)
const isVisible = ref(false)

const { stop } = useIntersectionObserver(
  targetRef,
  ([{ isIntersecting }], _observerElement) => {
    if (isIntersecting) {
      isVisible.value = true
    }
  },
  {
    threshold: 0.5
  }
)

onBeforeUnmount(() => {
  stop()
})
</script>

<style lang="postcss">
.shade {
  background: rgba(18, 226, 255, 0.5);
  box-shadow: 0px 4.4px 70.4px 11px rgba(18, 226, 255, 0.6);
}
</style>
