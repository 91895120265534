<template>
  <div
    :style="{
      width: responsive ? '100%' : width + 'px',
      height: responsive ? '100%' : height + 'px'
    }"
  >
    <canvas ref="canvasRef" class="!h-full !w-full"></canvas>
  </div>
</template>

<script setup lang="ts">
import { Application } from '@splinetool/runtime'

interface SplineRuntimeProps {
  /**
   * The URL of your Spline export. ex: https://prod.spline.design/6Wq1Q7YGyM-iab9i/scene.splinecode.
   */
  url: string
  width?: number
  height?: number
  /**
   * When true (default), this option makes the viewer responsive to its parent container sizes and ignores the width & height props.
   */
  responsive?: boolean
  variables?: Record<string, any>
  activeIdx?: number
}

const props = withDefaults(defineProps<SplineRuntimeProps>(), {
  responsive: false
})

let spline: Application | null = null
const canvasRef = ref<HTMLCanvasElement | null>(null)

const emit = defineEmits(['loaded'])

onMounted(() => {
  nextTick(() => {
    if (canvasRef.value) {
      spline = new Application(canvasRef.value)
      spline.load(props.url).then(() => {
        emit('loaded')

        // console.log('Spline loaded', spline)
        // spline?.emitEvent('mouseDown', 'Element')
        spline?.addEventListener('mouseUp', (e) => {
          // eslint-disable-next-line no-console
          console.log('Clicked!', e)
        })
        spline?.setVariable('selectedSlide', 1)
      })
    }
  })
})

onUnmounted(() => {
  if (spline) {
    spline.dispose()
  }
})

watch(
  () => props.activeIdx,
  (idx) => {
    if (!spline) {
      return
    }
    spline?.setVariable('selectedSlide', idx + 1)
  }
)
</script>
